import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Message } from 'element-ui';

// 引入iconfont.css
import "@/assets/fonts/iconfont.css"
// 引入reset.css
import "@/assets/css/reset.css"
// 引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 引入优化message
import { ElMessage } from '@/assets/js/elementui-api'
Vue.prototype.$message = new ElMessage()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
