<template>
  <el-card id="whiten">
    <img src="../assets/images/dy.png" class="subscribe" alt="">
    <header>
      <h1 style="font-weight: bold">运营商号码认证</h1>
    </header>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" :label-position="labelPosition">
      <el-form-item label="姓名">
        <el-input v-model="form.realname" @input="nameVal" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="form.phone" type="number" @input="phoneVal" placeholder="请输入电话"></el-input>
      </el-form-item>
      <!-- <el-form-item label="身份证">
        <el-input
          v-model="form.idcard"
          @input="idcardVal"
          placeholder="请输入身份证"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="appid">
        <el-input v-model="form.appid" @input="appidVal" placeholder="请输入appid"></el-input>
      </el-form-item>
      <el-form-item prop="identity" class="identity-wrap">
        <div style="height: 20px; margin-top: 20px">验证码</div>
        <div class="identity">
          <el-input v-model="identity" prefix-icon="el-icon-tickets" :disabled="sendCodeBool" @input="codeVal"
            placeholder="验证码 " style="margin-right: 10px"></el-input>
          <div>
            <!-- <Identify :identifyCode="identifyCode"></Identify> -->
            <el-button type="primary" @click="sendNote" v-if="!noteTiem">{{ noteText }}
            </el-button>
            <el-button type="primary" v-else="noteTiem" disabled>{{ noteTiem }}秒后重发
            </el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="addSubmit" style="width: 100%">确定提交
    </el-button>
    <!-- :disabled="getIdentityBol" -->
  </el-card>
</template>

<script>
import axios from "axios";
import Identify from "../components/Identity.vue";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
export default {
  components: { Identify },
  data() {
    return {
      labelPosition: 'right',
      sendCodeBool: true,
      noteTiem: 0,
      noteText: "获取验证码",
      noteBool: true,
      realname: null,
      phone: null,
      appid: null,
      identity: null,
      identityBol: true,
      identifyCode: "", // 验证码初始值
      identifyCodeB: "", // 验证码初始值
      identifyCodes: "1234567890", // 验证码的随机取值范围
      form: {},
      submitBool: false,
    };
  },
  mounted() {
    this.handleRefreshCode();
  },
  computed: {
    getIdentityBol() {
      return (
        !this.form.realname ||
        !this.form.phone ||
        !this.form.appid ||
        !this.identity
      );
    },
  },
  methods: {

    // 发送验证码
    sendNote() {
      if (this.form.phone && this.form.appid) {
        if (this.form.appid.length < 10) {
          this.$alert('appid错误，请重新输入appid', '', {
            confirmButtonText: '确定',
            callback: action => {
            }
          })
          return false
        }
        let params = {
          phone: this.form.phone,
          appid: this.form.appid
        };
        // axios
        // .post(
        //   "http://syshb.cddmt.cn:9999/vo//whitePhone-ydTwo/sms/get_code",
        //   params
        // )


        axios({
          method: 'post',
          // url: 'http://2hxwhite2.cddmt.cn/api/whitePhone-ydTwo/sms/get_code_admin',
          // url: 'http://syshb.cddmt.cn:9999/vo/whitePhone-ydTwo/add-admin',
          // url: 'http://syshb.cddmt.cn:9999/vo/whitePhone-ydTwo/sms/get_code',
          url: 'http://syshb.cddmt.cn/api/whitePhone-ydTwo/sms/get_code_qwt',
          // url: 'http://127.0.0.1:9999/vo/whitePhone-ydTwo/sms/get_code_qwt',
          headers: { //头部参数
            'Content-Type': 'application/json',
            "X-Access-Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODczMzU5MjgsInVzZXJuYW1lIjoieWluaG9uZyJ9.1nbnH-36o0TDKPH3Nnt4Wk7_-CLRJrc6k8Wc8tAds04"
          },
          data: params
        })
          .then((res) => {
            if (res.data.statusCode == "00000") {
              this.sendCodeBool = false
              this.noteText = "重新发送";
              this.noteTiem = 60;
              let tiemId = setInterval(() => {
                this.noteTiem--;
                if (this.noteTiem <= 0) {
                  clearInterval(tiemId);
                  this.noteTiem = 0;
                }
              }, 1000);
              Message.success(res.data);
            } else {
              Message.error(res.data);
            }
          });
      } else {
        this.$alert('发送验证码需要先填写电话号码和appid', '', {
          confirmButtonText: '确定',
          callback: action => {
          }
        })
      }
    },
    // 输入校验
    nameVal() {
      if (
        (this.form.realname != "" || this.form.realname != null) &&
        (this.form.phone != "" || this.form.phone != null) &&
        (this.form.appid != "" || this.form.appid != null) &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    phoneVal() {
      if (
        (this.form.realname != "" || this.form.realname != null) &&
        (this.form.phone != "" || this.form.phone != null) &&
        (this.form.appid != "" || this.form.appid != null) &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    idcardVal() {
      if (
        (this.form.realname != "" || this.form.realname != null) &&
        (this.form.phone != "" || this.form.phone != null) &&
        (this.form.appid != "" || this.form.appid != null) &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    appidVal() {
      if (
        (this.form.realname != "" || this.form.realname != null) &&
        (this.form.phone != "" || this.form.phone != null) &&
        (this.form.appid != "" || this.form.appid != null) &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    codeVal() {
      if (
        (this.form.realname != "" || this.form.realname != null) &&
        (this.form.phone != "" || this.form.phone != null) &&
        (this.form.appid != "" || this.form.appid != null) &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 提交表单
    addSubmit() {
      if (this.getIdentityBol) {
        this.$alert('请填写完整信息', '', {
          confirmButtonText: '确定',
          callback: action => {
          }
        })
        return false
      }

      if (this.submitBool) {
        Message.warning('操作频繁，请稍后再提交');
        return false
      }
      this.submitBool = true
      setTimeout(() => {
        this.submitBool = false
      }, 3000)
      let params = {
        realname: this.form.realname,
        phone: this.form.phone,
        appid: this.form.appid,
        // code: this.identity,
        status: 1  // 无需验证码时传此参数  反之 传0
      };
      // axios
      // .post("http://47.103.146.48:9999/vo/whitePhone-ydTwo/sms/add", params)
      // .post("http://syshb.cddmt.cn:9999/vo/whitePhone-ydTwo/sms/add", params)
      // .post("http://syshb.cddmt.cn:9999/vo/whitePhone-ydTwo/add-admin", {
      //   data:params,
      //   headers: { //头部参数
      //     'Content-Type': 'application/json',
      //     "X-Access-Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODczMzU5MjgsInVzZXJuYW1lIjoieWluaG9uZyJ9.1nbnH-36o0TDKPH3Nnt4Wk7_-CLRJrc6k8Wc8tAds04"
      //   }
      // })
      axios({
        method: 'post',
        url: 'http://syshb.cddmt.cn/api/whitePhone-ydTwo/add-admin',
        // url: 'http://syshb.cddmt.cn:9999/vo/whitePhone-ydTwo/sms/add',
        // url: 'http://127.0.0.1:9999/vo/whitePhone-ydTwo/add-admin',

        headers: { //头部参数
          'Content-Type': 'application/json',
          "X-Access-Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODczMzU5MjgsInVzZXJuYW1lIjoieWluaG9uZyJ9.1nbnH-36o0TDKPH3Nnt4Wk7_-CLRJrc6k8Wc8tAds04"
        },
        data: params
      })
        .then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data);
          } else {
            Message.error(res.data.message + res.data.data);
          }
        });
    },

    // 刷新验证码
    handleRefreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    },
  },
};
</script>

<style lang="less" scoped>
#whiten {
  position: relative;
  width: 80%;
  margin: auto;
  margin-top: 4%;
  padding: 20px;
  box-sizing: border-box;

  .subscribe {
    width: 100px;
    position: absolute;
    top: 6px;
    right: 10px;
  }

  // height: 100vh;
  header {
    h1 {
      font-weight: normal;
    }
  }

  .identity-wrap {
    display: flex;
    flex-direction: column;
    text-align: left;

    .identity {
      display: flex;
      align-items: center;
    }

    /deep/.el-form-item__content {
      line-height: 0px;
    }
  }
}
</style>